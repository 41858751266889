import { person, contact, documentDetailsCorporateStakeholders } from './common'
import { TYPE } from '../../formTypes'
import {
  annualTurnoverEu,
  businessTypesEu,
  corporateDocumentTypeEu,
  industrySector,
  intendedUseOfAccount,
  personDocumentTypeEu,
  positionEu,
  totalEmployees,
  yesOrNo,
  formatValueMap,
  stakeholderTypes,
} from '../values'
import { NIUM_STAKEHOLDER_TYPE } from 'constants.js'

export const address = formatMessage => ({
  addressLine1: {
    label: formatMessage({ id: 'kyb-form.address-line-1' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  addressLine2: {
    label: formatMessage({ id: 'kyb-form.address-line-2' }),
    type: TYPE.STRING,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  city: {
    label: formatMessage({ id: 'kyb-form.city' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
  country: {
    label: formatMessage({ id: 'kyb-form.country' }),
    type: TYPE.COUNTRY,
    required: true,
  },
  postcode: {
    label: formatMessage({ id: 'kyb-form.postcode' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
      'string.pattern.base',
    ],
  },
  state: {
    label: formatMessage({ id: 'kyb-form.state' }),
    type: TYPE.STRING,
    required: true,
    customErrorMessageTypes: [
      'string.max',
    ],
  },
})

export const documentDetailsCorporate = formatMessage => ({
  documentType: {
    label: formatMessage({ id: 'kyb-form.document-type' }),
    type: TYPE.SELECT,
    options: formatValueMap(corporateDocumentTypeEu, formatMessage),
    required: true,
  },
  "document.0.document": {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE,
    info: formatMessage({ id: 'kyb-form.file-size-limit-1-5-mb-accepted-formats-pdf-jpg-png' }),
    required: true,
  },
  documentNumber: {
    label: formatMessage({ id: 'kyb-form.document-number' }),
    type: TYPE.STRING,
    required: true,
    info: formatMessage({ id: 'kyb-form.e-g-incorporation-number-or-passport-etc' }),
  },
  documentIssuanceCountry: {
    label: formatMessage({ id: 'kyb-form.document-issuance-country' }),
    type: TYPE.COUNTRY,
    required: true,
  },
})

export const documentDetailsPerson = formatMessage => ({
  documentType: {
    label: formatMessage({ id: 'kyb-form.document-type' }),
    type: TYPE.SELECT,
    info: formatMessage({ id: 'kyb-form.passport-is-required' }),
    options: formatValueMap(personDocumentTypeEu, formatMessage),
    required: true
  },
  "document.0.document": {
    label: formatMessage({ id: 'kyb-form.document' }),
    type: TYPE.FILE,
    info: formatMessage({ id: 'kyb-form.file-size-limit-1-5-mb-accepted-formats-pdf-jpg-png' }),
    required: true
  },
  documentNumber: {
    label: formatMessage({ id: 'kyb-form.document-number' }),
    type: TYPE.STRING,
    required: true,
    info: formatMessage({ id: 'kyb-form.e-g-incorporation-number-or-passport-etc' }),
  },
  documentIssuanceCountry: {
    label: formatMessage({ id: 'kyb-form.document-issuance-country' }),
    type: TYPE.COUNTRY,
    required: true
  },
  documentExpiryDate: {
    label: formatMessage({ id: 'kyb-form.document-expiry-date' }),
    info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
    type: TYPE.DATE,
    future: true,
  },
  documentIssuedDate: {
    label: formatMessage({ id: 'kyb-form.document-issued-date' }),
    info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
    type: TYPE.DATE,
    past: true,
  },
  documentIssuingAuthority: {
    label: formatMessage({ id: 'kyb-form.document-issuing-authority' }),
    type: TYPE.STRING,
    required: true
  },
})

export const stakeholdersFields = formatMessage => ({
  stakeholderType: {
    type: TYPE.RADIO,
    options: formatValueMap(stakeholderTypes, formatMessage),
    required: true
  },
  businessPartner: {
    title: formatMessage({ id: 'kyb-form.stakeholder-details' }),
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.CORPORATE
    },
    fields: {
      businessName: {
        label: formatMessage({ id: 'kyb-form.business-name' }),
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: formatMessage({ id: 'kyb-form.business-registration-number' }),
        type: TYPE.STRING,
        required: true
      },
      businessEntityType: {
        label: formatMessage({ id: 'kyb-form.stakeholder-type' }),
        type: TYPE.SELECT,
        options: formatValueMap(positionEu, formatMessage),
        required: true
      },
      sharePercentage: {
        label: formatMessage({ id: 'kyb-form.share-percentage' }),
        type: TYPE.STRING,
        decimalDigits: 2,
        info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
      },
      "legalDetails.registeredCountry": {
        label: formatMessage({ id: 'kyb-form.registered-country' }),
        type: TYPE.COUNTRY,
        required: true
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporateStakeholders(formatMessage)]
      },
    },
  },
  stakeholderDetails: {
    title: formatMessage({ id: 'kyb-form.stakeholder-details' }),
    type: TYPE.GROUP,
    renderIf: {
      'stakeholders.index.stakeholderType': NIUM_STAKEHOLDER_TYPE.INDIVIDUAL
    },
    fields: {
      ...person(formatMessage),
      birthCountry: {
        label: formatMessage({ id: 'kyb-form.birth-country' }),
        type: TYPE.COUNTRY,
        required: true
      },
      isPep: {
        label: formatMessage({ id: 'kyb-form.is-politically-exposed-person' }),
        type: TYPE.RADIO,
        options: formatValueMap(yesOrNo, formatMessage),
        required: true
      },
      address: {
        title: formatMessage({ id: 'kyb-form.address-details' }),
        type: TYPE.GROUP,
        fields: address(formatMessage),
      },
      contactDetails: {
        title: formatMessage({ id: 'kyb-form.contact-details' }),
        type: TYPE.GROUP,
        fields: {
          ...contact(formatMessage),
        }
      },
      "professionalDetails.0": {
        title: formatMessage({ id: 'kyb-form.professional-details' }),
        type: TYPE.GROUP,
        fields: {
          position: {
            label: formatMessage({ id: 'kyb-form.position' }),
            type: TYPE.SELECT,
            options: formatValueMap(positionEu, formatMessage)
          },
          positionStartDate: {
            label: formatMessage({ id: 'kyb-form.position-start-date' }),
            type: TYPE.DATE,
            past: true,
          },
          sharePercentage: {
            label: formatMessage({ id: 'kyb-form.share-percentage' }),
            type: TYPE.STRING,
            decimalDigits: 2,
            info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
          },
        }
      },
      "taxDetails.0": {
        title: formatMessage({ id: 'kyb-form.tax-details' }),
        type: TYPE.GROUP,
        fields: {
          country: {
            label: formatMessage({ id: 'kyb-form.country' }),
            type: TYPE.COUNTRY,
            required: true,
          },
          taxNumber: {
            label: formatMessage({ id: 'kyb-form.tax-number' }),
            type: TYPE.STRING,
            required: true,
            customErrorMessageTypes: [
              'string.max',
            ],
          },
        },
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        type: TYPE.GROUP,
        multiple: true,
        fields: [{
          ...documentDetailsPerson(formatMessage),
          documentIssuedDate: {
            label: formatMessage({ id: 'kyb-form.document-issued-date' }),
            info: formatMessage({ id: 'kyb-form.please-enter-fictitious-dates-if-not-applicable' }),
            type: TYPE.DATE,
            past: true,
            required: true
          },
        }]
      },
    },
  },
})

export const formStructure = formatMessage => ({
  region: {
    title: formatMessage({ id: 'kyb-form.region' }),
    type: TYPE.GROUP,
    fields: {
      region: {
        label: formatMessage({ id: 'kyb-form.region' }),
        type: TYPE.SELECT,
        options: { EU: 'EU' },
        required: true,
      }
    }
  },
  businessDetails: {
    title: formatMessage({ id: 'kyb-form.business-details' }),
    type: TYPE.GROUP,
    fields: {
      businessName: {
        label: formatMessage({ id: 'kyb-form.business-name' }),
        type: TYPE.STRING,
        required: true
      },
      businessRegistrationNumber: {
        label: formatMessage({ id: 'kyb-form.business-registration-number' }),
        type: TYPE.STRING,
        required: true
      },
      tradeName: {
        label: formatMessage({ id: 'kyb-form.trade-name' }),
        type: TYPE.STRING,
        required: true
      },
      website: {
        label: formatMessage({ id: 'kyb-form.website' }),
        type: TYPE.STRING
      },
      businessType: {
        label: formatMessage({ id: 'kyb-form.business-type' }),
        type: TYPE.SELECT,
        options: formatValueMap(businessTypesEu, formatMessage),
        required: true
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.document-details' }),
        info: formatMessage({ id: 'kyb-form.for-nium-to-perform-electronic-verification-of-company-data-the-following-information-is-required-where-applicable' }) + ' <b>' + 
          formatMessage({ id: 'kyb-form.note' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.documents-must-be-uploaded-in-pdf-jpg-or-png-format' }) + ':<br/><ul><li><b>' +
          formatMessage({ id: 'kyb-form.for-uk-entities' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.company-extract-or-certificate-of-incorporation-proof-of-business-address-if-different-from-operations-address' }) + '</li><li><b>' + 
          formatMessage({ id: 'kyb-form.corporate-documents-required-for-all-entities' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.1-certificate-of-incorporation-2-business-registration-certificate-or-equivalent-3-memorandum-articles-of-incorporation-4-latest-board-resolution-if-applicable-5-certificate-of-incumbency-if-applicable-6-share-registry-if-applicable' }) + '</li><li><b>' +
          formatMessage({ id: 'kyb-form.multiple-ownership-layers' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.provide-an-organization-chart-with-shareholding-structure-and-ub-os-with-percentages-signed-and-dated-by-an-authorized-representative' }) + '</li><li><b>' +
          formatMessage({ id: 'kyb-form.mandatory-documents-for-us-entities' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.articles-of-incorporation-and-evidence-of-good-standing' }) + '</li></ul>',
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsCorporate(formatMessage)]
      },
      legalDetails: {
        title: formatMessage({ id: 'kyb-form.legal-registration-details' }),
        type: TYPE.GROUP,
        fields: {
          registeredDate: {
            label: formatMessage({ id: 'kyb-form.registration-date' }),
            type: TYPE.DATE,
            past: true,
            info: formatMessage({ id: 'kyb-form.the-date-the-business-was-originally-registered' }),
            required: true
          },
          registeredCountry: {
            label: formatMessage({ id: 'kyb-form.registered-country' }),
            type: TYPE.COUNTRY,
            required: true
          },
        }
      },
      "taxDetails.0": {
        title: formatMessage({ id: 'kyb-form.tax-details' }),
        type: TYPE.GROUP,
        info: formatMessage({ id: 'kyb-form.if-your-company-is-a-subsidiary-or-a-part-of-a-group-of-companies-provide-the-country-where-taxes-are-paid-for-your-own-legal-entity-and-not-for-the-entire-group-of-companies' }),
        fields: {
          country: {
            label: formatMessage({ id: 'kyb-form.country' }),
            type: TYPE.COUNTRY,
            options: ['AT', 'BE', 'HR', 'CY', 'EE', 'FI', 'FR', 'DE', 'GR', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SI', 'ES'],
            required: true
          },
          taxNumber: {
            label: formatMessage({ id: 'kyb-form.tax-number' }),
            type: TYPE.STRING,
            required: true
          },
        },
      },
      "addresses": {
        title: formatMessage({ id: 'kyb-form.addresses' }),
        type: TYPE.GROUP,
        fields: {
          registeredAddress: {
            title: formatMessage({ id: 'kyb-form.registered-address' }),
            noExpand: true,
            type: TYPE.GROUP,
            fields: address(formatMessage),
          },
          isSameAddress: {
            label: formatMessage({ id: 'kyb-form.is-your-business-address-same-as-your-registered-address' }),
            type: TYPE.RADIO,
            options: formatValueMap(yesOrNo, formatMessage),
            required: true,
          },
          businessAddress: {
            title: formatMessage({ id: 'kyb-form.business-address' }),
            noExpand: true,
            type: TYPE.GROUP,
            fields: address(formatMessage),
            visibleIfFalse: 'businessDetails.addresses.isSameAddress',
          },
        },
      },
    }
  },
  stakeholders: {
    title: formatMessage({ id: 'kyb-form.stakeholders' }),
    info: formatMessage({ id: 'kyb-form.stakeholders-include-the-following-roles-and-all-applicable-persons-must-be-added-and-completed' }) +  '<b>' +
      formatMessage({ id: 'kyb-form.note' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.documents-must-be-uploaded-in-pdf-jpg-or-png-format' }) + '<br /><ul><li><b>' +
      formatMessage({ id: 'kyb-form.directors-uk-eu' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.include-at-least-two-directors-managing-day-to-day-affairs' }) + '</li><li><b>' +
      formatMessage({ id: 'kyb-form.control-person' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.for-us-please-include-one-control-person-for-uk-eu-include-all-individuals-with-significant-control-over-the-company' }) + '</li><li><b>' +
      formatMessage({ id: 'kyb-form.individual-shareholders-ub-os' }) + '</b>: ' +
      formatMessage({ id: 'kyb-form.ub-os-must-be-natural-persons-individuals-except-for-publicly-traded-businesses-list-ub-os-with-25-ownership-or-more-or-10-or-more-for-pe-ps-us-persons-only-select-social-security-number-for-government-id-type-and-provide-the-last-four-digits-of-ssn-under-the-government-id-number-section-note-if-the-stakeholder-s-position-is-ubo-then-the-share-percentage-is-a-required-input-parameter' }) + '</li></ul>',
    type: TYPE.GROUP,
    multiple: true,
    fields: [stakeholdersFields(formatMessage)]
  },
  applicantDetails: {
    title: formatMessage({ id: 'kyb-form.applicant-details' }),
    info: formatMessage({ id: 'kyb-form.all-clients-are-required-to-complete-this-section-the-applicant-must-be-an-authorized-representative-appointed-either-as-a-director-or-an-authorized-signatory-designated-by-the-company-s-director-or-via-power-of-attorney-to-execute-and-sign-transactions-on-behalf-of-the-company' }),
    type: TYPE.GROUP,
    fields: {
      ...person(formatMessage),
      birthCountry: {
        label: formatMessage({ id: 'kyb-form.birth-country' }),
        type: TYPE.COUNTRY,
        required: true
      },
      isPep: {
        label: formatMessage({ id: 'kyb-form.is-politically-exposed-person' }),
        type: TYPE.RADIO,
        options: formatValueMap(yesOrNo, formatMessage),
        required: true
      },
      contactDetails: {
        title: formatMessage({ id: 'kyb-form.applicants-contact-details' }),
        type: TYPE.GROUP,
        fields: contact(formatMessage),
      },
      address: {
        title: formatMessage({ id: 'kyb-form.applicants-address-details' }),
        type: TYPE.GROUP,
        info: formatMessage({ id: 'kyb-form.the-applicant-s-address-details-is-where-you-provide-your-current-and-personal-place-of-residence-you-will-need-to-enter-your-complete-address-including-the-house-number-street-name-town-or-city-county-or-state-postal-code' }),
        fields: address(formatMessage),
      },
      documentDetails: {
        title: formatMessage({ id: 'kyb-form.applicants-document-details' }),
        info: formatMessage({ id: 'kyb-form.clients-must-provide-the-following-documents-where-applicable' }) + ' <b>' +
          formatMessage({ id: 'kyb-form.note' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.documents-must-be-uploaded-in-pdf-jpg-or-png-format' }) + '<br /><ul><li><b>' +
          formatMessage({ id: 'kyb-form.photo-identification-proof-of-address' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.please-provide-one-form-of-photo-identification-e-g-passport-or-driving-license-if-the-identification-does-not-include-a-residential-address-a-proof-of-address-e-g-utility-bill-must-also-be-provided' }) + '</li><li><b>' +
          formatMessage({ id: 'kyb-form.for-letter-of-authority' }) + '</b>: ' +
          formatMessage({ id: 'kyb-form.if-not-a-director-of-the-company-an-additional-document-certified-letter-of-authority-must-also-be-provided-and-signed-by-a-director' }) + '</li></ul>',
        type: TYPE.GROUP,
        multiple: true,
        fields: [documentDetailsPerson(formatMessage)]
      },
      "professionalDetails.0": {
        title: formatMessage({ id: 'kyb-form.applicants-professional-details' }),
        type: TYPE.GROUP,
        fields: {
          position: {
            label: formatMessage({ id: 'kyb-form.position' }),
            type: TYPE.SELECT,
            options: formatValueMap(positionEu, formatMessage),
            required: true
          },
          positionStartDate: {
            label: formatMessage({ id: 'kyb-form.position-start-date' }),
            type: TYPE.DATE,
            past: true,
            required: true
          },
          sharePercentage: {
            label: formatMessage({ id: 'kyb-form.share-percentage' }),
            type: TYPE.STRING,
            decimalDigits: 2,
            info: formatMessage({ id: 'kyb-form.enter-0-if-no-shares-held-do-not-add-the-sign' }),
          },
        }
      },
    }
  },
  riskAssessmentInfo: {
    title: formatMessage({ id: 'kyb-form.additional-business-information' }),
    type: TYPE.GROUP,
    fields: {
      totalEmployees: {
        label: formatMessage({ id: 'kyb-form.total-employees' }),
        type: TYPE.SELECT,
        options: formatValueMap(totalEmployees, formatMessage),
        required: true
      },
      annualTurnover: {
        label: formatMessage({ id: 'kyb-form.annual-turnover' }),
        type: TYPE.SELECT,
        options: formatValueMap(annualTurnoverEu, formatMessage),
        required: true
      },
      industrySector: {
        label: formatMessage({ id: 'kyb-form.industry-sector' }),
        type: TYPE.SELECT,
        options: formatValueMap(industrySector, formatMessage),
        required: true
      },
      intendedUseOfAccount: {
        label: formatMessage({ id: 'kyb-form.intended-use-of-account' }),
        type: TYPE.SELECT,
        options: formatValueMap(intendedUseOfAccount, formatMessage),
        required: true,
      },
      transactionCountries: {
        label: formatMessage({ id: 'kyb-form.transaction-countries-you-make-payments-to' }),
        type: TYPE.COUNTRY,
        multiple: true,
        required: true,
        info: formatMessage({ id: 'kyb-form.please-select-countries-you-make-payments-to' }),
      },
      countryOfOperation: {
        label: formatMessage({ id: 'kyb-form.countries-of-operation' }),
        type: TYPE.COUNTRY,
        multiple: true,
        required: true,
        info: formatMessage({ id: 'kyb-form.please-select-countries-where-you-have-offices-or-operations' }),
      },
      
    }
  },
})
