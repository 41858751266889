import { makeStyles } from '@material-ui/core'
import { green } from '@material-ui/core/colors'

export const useStyles = makeStyles((theme) => ({
  rootSection: {
    margin: '24px 0 !important',
    '&.Mui-expanded': {
      border: '2px solid'
    }
  },
  buttonContainerSection: {
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.default,
    padding: '0 1em'
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  datePicker: {
    display: 'flex',
    flexGrow: 1,
    marginTop: theme.spacing(2),
  },
  field: {
    marginTop: theme.spacing(2),
  },
  fileField: {
    marginTop: theme.spacing(2),
    "& legend": {
      visibility: "visible"
    }
  },
  buttonSpacing: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    backgroundColor: theme.palette.primary.main
  },
  fullWidth: {
    flexGrow: 1,
    maxWidth: '100%'
  },
  hidden: {
    display: 'none',
  },
  selectClear: {
    marginRight: theme.spacing(2),
  },
  primaryLabel: {
    color: theme.palette.primary.main,
  },
  heading: {
    fontWeight: 'bold !important',
  },
  formContainer: {
    maxWidth: '100%',
  },
  info: {
    "& li": {
      marginBottom: theme.spacing(1),
    }
  },
  successIcon: {
    color: green[500]
  },
  icon: {
    marginRight: theme.spacing(1),
  }
}))
