import { kebabCase } from "lodash"
import { NIUM_STAKEHOLDER_TYPE } from "../../../../../../../constants"

// common
export const totalEmployees = {
  EM006: 'Less than or equal 1',
  EM007: 'Between 2 to 5',
  EM008: 'Between 6 to 24',
  EM009: 'More than 24',
}

export const industrySector = {
  IS228: 'Travel & Tourism',
  IS244: 'Technology providers',
}

export const intendedUseOfAccount = {
  IU002: 'Pay suppliers and vendors (defaulted)',
}

// all positions

const allPositions = {
  DIRECTOR: { DIRECTOR: 'Director' },
  UBO: { UBO: 'Owner/Ultimate Beneficial Owners (UBO)' },
  SHAREHOLDER: { SHAREHOLDER: 'Shareholder' },
  TRUSTEE: { TRUSTEE: 'Trustee' },
  SETTLOR: { SETTLOR: 'Settlor' },
  PARTNER: { PARTNER: 'Partner' },
  MEMBERS: { MEMBERS: 'Members' },
  SIGNATORY: { SIGNATORY: 'Authorized Signatory' },
  REPRESENTATIVE: { REPRESENTATIVE: 'Authorized Representative' },
}

// uk
export const positionUk = {
  ...allPositions.DIRECTOR,
  ...allPositions.UBO,
  ...allPositions.SHAREHOLDER,
  ...allPositions.TRUSTEE,
  ...allPositions.SETTLOR,
  ...allPositions.PARTNER,
  ...allPositions.MEMBERS,
  ...allPositions.SIGNATORY,
  ...allPositions.REPRESENTATIVE,
}

export const corporateDocumentTypeStakeholder = {
  REGISTRATION_DOC: 'Registration Document',
  UBO_DECLARATION: 'UBO Declaration',
  CONTROLLING_PERSON_DECLARATION: 'Controlling Person Declaration',
}

// full list filtered per Roberto's feedback
export const businessTypesUk = {
  SOLE_TRADER: 'Sole Trader',
  PRIVATE_COMPANY: 'Private Limited Company',
  PUBLIC_COMPANY: 'Public Company',
  TRUST: 'Trust',
  LIMITED_LIABILITY_PARTNERSHIP: 'Limited Liability Partnership Firm',
  UNINCORP_PARTNERSHIP: 'Partnership',
  // GOVERNMENT_ENTITY: 'Government Body',
  ASSOCIATION: 'Associations',
  // OTHERS: 'Others'
}

export const corporateDocumentTypeUk = {
  BUSINESS_REGISTRATION_DOC: 'Registration document',
  TRUST_DEED: 'Trust Deed',
  PARTNERSHIP_DEED: 'Partnership Deed',
  ASSOCIATION_DEED: 'Association Deed',
}

export const identityDocTypesUk = {
  DRIVER_LICENCE: 'Driver\'s License (front and back)',
  NATIONAL_ID: 'National ID',
  PASSPORT: 'Passport',
}

export const addressDocTypesUk = {
  PROOF_OF_ADDRESS: 'Proof Of Address',
}

export const authorityDocTypesUk = {
  POWER_OF_ATTORNEY: 'Letter of Authority',
}

export const personDocumentTypeUk = {
  ...identityDocTypesUk,
  ...addressDocTypesUk,
  ...authorityDocTypesUk,
}

export const allDocumentTypesUk = {
  ...corporateDocumentTypeUk,
  ...personDocumentTypeUk,
}

export const annualTurnoverUk = {
  GB008: 'Less than GBP 100,000',
  GB009: 'GBP 100,000 to GBP 500,000',
  GB010: 'GBP 500,000 to GBP 1,500,000',
  GB011: 'GBP 1,500,000 +',
}

export const transactionCountriesUk = [
  'GB',
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IS',
  'IE',
  'IT',
  'LV',
  'LI',
  'LT',
  'LU',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
]

export const restrictedCountries = [
  'AF', 
  'YE', 
  'SY', 
  'SD', 
  'SS', 
  'SO', 
  'RU', 
  'MM', 
  'LY', 
  'KP', 
  'IQ', 
  'IR', 
  'GW', 
  'ER', 
  'CF', 
  'BI', 
  'BY', 
  'CU',
]

// eu
export const positionEu = {
  ...allPositions.DIRECTOR,
  ...allPositions.UBO,
  ...allPositions.SHAREHOLDER,
  ...allPositions.TRUSTEE,
  ...allPositions.PARTNER,
  ...allPositions.SETTLOR,
  ...allPositions.SIGNATORY,
  ...allPositions.REPRESENTATIVE,
}

// full list filtered per Roberto's feedback
export const businessTypesEu = {
  SOLE_TRADER: 'Sole Trader',
  PRIVATE_COMPANY: 'Private Limited Company',
  PUBLIC_COMPANY: 'Public Company',
  TRUST: 'Trust',
  LIMITED_LIABILITY_PARTNERSHIP: 'Limited Liability Partnership Firm',
  // GOVERNMENT_ENTITY: 'Government Body',
  ASSOCIATION: 'Associations'
}

export const businessTypePositionMap = {
  SOLE_TRADER: {
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  PRIVATE_COMPANY: {
    ...allPositions.DIRECTOR,
    ...allPositions.UBO,
    ...allPositions.SHAREHOLDER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  PUBLIC_COMPANY: {
    ...allPositions.DIRECTOR,
    ...allPositions.UBO,
    ...allPositions.SHAREHOLDER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  TRUST: {
    ...allPositions.TRUSTEE,
    ...allPositions.SETTLOR,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  LIMITED_LIABILITY_PARTNERSHIP: {
    ...allPositions.PARTNER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
  // GOVERNMENT_ENTITY: [
  //  ...allPositions.SIGNATORY,
  //  ...allPositions.REPRESENTATIVE,
  // ],
  ASSOCIATION: {
    ...allPositions.DIRECTOR,
    ...allPositions.SHAREHOLDER,
    ...allPositions.SIGNATORY,
    ...allPositions.REPRESENTATIVE,
  },
}

export const corporateDocumentTypeEu = {
  BUSINESS_REGISTRATION_DOC: 'Registration document',
  REGISTER_OF_DIRECTORS: 'Register of Directors',
  REGISTER_OF_SHAREHOLDERS: 'Register of Shareholders',
  TRUST_DEED: 'Trust Deed',
  PARTNERSHIP_DEED: 'Partnership Deed',
  ASSOCIATION_DEED: 'Association Deed',
}

export const personDocumentTypeEu = {
  NATIONAL_ID: 'National ID',
  PASSPORT: 'Passport',
  POWER_OF_ATTORNEY: 'Certified Letter of Authority',
  PROOF_OF_ADDRESS: 'Proof Of Address',
}

export const allDocumentTypesEu = {
  ...corporateDocumentTypeEu,
  ...personDocumentTypeEu,
}

export const annualTurnoverEu = {
  EU008: 'Less than EUR 100,000',
  EU009: 'EUR 100,000 to EUR 500,000',
  EU010: 'EUR 500,000 to EUR 1,500,000',
  EU011: 'EUR 1,500,000 +',
}

export const yesOrNo = {
  YES: 'Yes',
  NO: 'No',
}

export const stakeholderTypes = {
  [NIUM_STAKEHOLDER_TYPE.INDIVIDUAL]: 'Individual',
  [NIUM_STAKEHOLDER_TYPE.CORPORATE]: 'Corporate (If owned by another company)'
}

export function formatValueMap(obj, formatMessage) {
  if (obj !== null && !Array.isArray(obj) && typeof obj === 'object') {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) =>
        typeof value === 'string'
          ? [key, formatMessage({ id: `kyb-form.${kebabCase(key)}` })]
          : [key, value]
      )
    )
  }
  return obj
}
