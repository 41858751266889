import React from 'react'
import { isProduction, ROUTE_URL } from 'constants.js'
import BreadcrumbMenuBase from './BreadcrumbMenuBase'
import { useIntl } from 'react-intl'

const BreadcrumbMenuTransactions = () => {
  const { formatMessage } = useIntl()

  return (
    <BreadcrumbMenuBase links={[
      {
        label: formatMessage({ id: 'breadcrumb-menu.bookings' }),
        to: ROUTE_URL.bookingsReview
      },
      {
        label: formatMessage({ id: 'breadcrumb-menu.remittances' }),
        to: ROUTE_URL.remittancesReview
      },
      !isProduction &&
      {
        label: formatMessage({ id: 'breadcrumb-menu.payments-statement' }),
        to: ROUTE_URL.paymentsStatement
      },
      {
        label: formatMessage({ id: 'breadcrumb-menu.file-uploads' }),
        to: ROUTE_URL.csv
      }
    ].filter(Boolean)} />
  )
}

export default BreadcrumbMenuTransactions
