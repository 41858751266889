/* eslint-disable no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import FormSelect from 'components/FormSelect'
import Tooltip from '@material-ui/core/Tooltip'
import TextField from '@material-ui/core/TextField'
import IconButton from '@material-ui/core/IconButton'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import { useStyles } from './styles'
import Typography from '@material-ui/core/Typography'
import Button from 'components/Button'
import { COMPANY_ID_TYPES, READ_ONLY_COMPANY_ID_TYPES } from 'constants.js'
import { useIntl } from 'react-intl'

const getMaxLengthAccordingType = (type) => {
  switch (type) {
    case COMPANY_ID_TYPES.ABTA: return 5
    case COMPANY_ID_TYPES.IATA: return 12
    case COMPANY_ID_TYPES.TIDS: return 12
    case COMPANY_ID_TYPES.EMAIL_ID: return 30
    case READ_ONLY_COMPANY_ID_TYPES.TL_S: return 30
    default: return 0
  }
}

const getErrorByLine = (error, line) => {
  if (Array.isArray(error) && error[line]) {
    return error[line]?.id?.message
  }
  return
}

const getUniqueOptionsKeys = (value, typeLine) => {
  return Object.entries(COMPANY_ID_TYPES)
    .filter(([ typeOption ]) => typeLine === typeOption || !value?.[typeOption] )
    .reduce((obj, [ typeOption, idOption ]) => ({ ...obj, [typeOption]: idOption }), {})
}

const getNextAvailableOption = (value) => {
  const entry = Object.keys(COMPANY_ID_TYPES).find((typeOption) => !value?.[typeOption])
  if (!entry) {
    return undefined
  }
  return entry
}

// eslint-disable-next-line react/display-name
export const ReactHookFormCompanyIds = ({
  value,
  onChange,
  label,
  error
}) => {

  const classes = useStyles()
  const textFieldProps = { fullWidth: true, className: classes.formInput }
  const { formatMessage } = useIntl()

  const hasShowRemoveBtn = value && Object.keys(value).length

  const handleAddNewCompany = () => {
    const availableOption = getNextAvailableOption(value)
    if (!availableOption) {
      return
    }
    return onChange({ ...value, [availableOption]: '' })
  }

  const handleChangeValue = (typeOption, keyOrValue) => (event) => {
    const { value: _value } = event.target
    if (keyOrValue === 'key') {
      const updatedValue = { ...value }
      Reflect.deleteProperty(updatedValue, typeOption)
      updatedValue[_value] = ''
      return onChange(updatedValue)
    }
    if (_value.length <= getMaxLengthAccordingType(typeOption)) {
      onChange({ ...value, [typeOption]: _value })
    }
  }

  const handleRemoveCompany = (typeLine) => {
    const updatedValue = { ...value }
    updatedValue[typeLine] = null
    onChange(updatedValue)
  }

  // Filter out read only
  const codes = Object.entries(value || {}).filter(([ type ]) => !Object.keys(READ_ONLY_COMPANY_ID_TYPES).includes(type))

  return (
    <Grid container item xs={12}>
      <Card className={classes.topTransactionPaper}>
        { label ? (<Typography variant="body2">{label}</Typography>) : null }
        {
          value ? codes.map(([ typeOption, idOption ], k) => idOption !== null && (
            <Grid key={typeOption} direction="row" container item sm={12}>
              <Grid className={classes.fieldsMargin} item sm={4}>
                <FormSelect
                  {...textFieldProps}
                  name={`type_${k}`}
                  label={formatMessage({ id: 'companies-and-users.type' })}
                  onChange={handleChangeValue(typeOption, 'key')}
                  value={typeOption}
                  keyTitle={getUniqueOptionsKeys(value, typeOption)}
                />
              </Grid>
              <Grid className={classes.fieldsMargin} item sm={7}>
                <TextField
                  {...textFieldProps}
                  name={`company_id_${k}`}
                  label={formatMessage({ id: 'companies-and-users.company-id' })}
                  value={idOption}
                  onChange={handleChangeValue(typeOption, 'value')}
                  required={k === 0}
                  error={!!getErrorByLine(error, k)}
                  helperText={getErrorByLine(error, k)}
                />
              </Grid>
              <Grid container item sm={1} alignItems="center" style={{ marginTop: 30 }}>
                {hasShowRemoveBtn && (
                  <Tooltip title="Remove company line">
                    <IconButton onClick={() => handleRemoveCompany(typeOption)}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          )) : null
        }
        { error && !Array.isArray(error) ? (<Typography color="error" variant="caption">{error}</Typography>) : null }
      </Card>
      <Grid container justify="flex-end">
        <Button
          color="primary"
          onClick={handleAddNewCompany}
          icon="add"
        >
          {formatMessage({ id: 'companies-and-users.add-company-id' })}
        </Button>
      </Grid>
    </Grid>
  )
}

ReactHookFormCompanyIds.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ])
}

export default ReactHookFormCompanyIds
