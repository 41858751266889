import { 
  businessTypesEu as businessTypes,
  corporateDocumentTypeEu as corporateDocumentType, 
  personDocumentTypeEu as personDocumentType,
  annualTurnoverEu as annualTurnover,
  positionEu as position,
  industrySector,
  totalEmployees,
  intendedUseOfAccount,
  corporateDocumentTypeStakeholder,
} from "../values";
import JoiBase from 'joi'
import JoiDate from '@joi/date'
import { NIUM_STAKEHOLDER_TYPE } from 'constants.js'

const Joi = JoiBase.extend(JoiDate)

const corporateDocumentSchema = (isStakeholder = false) => Joi.array().items(Joi.object({
  documentType: Joi.string().valid(...Object.keys(isStakeholder ? corporateDocumentTypeStakeholder : corporateDocumentType)).required().empty(''),
  document: Joi.array().items(Joi.object({
    document: Joi.any().required(),
    fileName: Joi.string(),
    fileType: Joi.string(),
  })).required(),
  documentNumber: Joi.string().required().empty(''),
  documentIssuanceCountry: Joi.string().required().empty(''),
}))

const personDocumentSchema = (requireIssuedDate) => Joi.array().items(Joi.object({
  documentType: Joi.string().valid(...Object.keys(personDocumentType)).empty(''),
  document: Joi.array().items(Joi.object({
    document: Joi.any().required(),
    fileName: Joi.string(),
    fileType: Joi.string(),
  })).required(),
  documentNumber: Joi.string().required().empty(''),
  documentIssuanceCountry: Joi.string().required().empty(''),
  documentExpiryDate: Joi.date().when('documentType', {
    is: Joi.any().valid('DRIVER_LICENCE', 'NATIONAL_ID', 'PASSPORT'),
    then: Joi.date().format('YYYY-MM-DD').raw().min('now').required(),
    otherwise: Joi.date().format('YYYY-MM-DD').raw().min('now'),
  }),
  documentIssuedDate: requireIssuedDate ? Joi.date().format('YYYY-MM-DD').raw().max('now').required() : Joi.date().format('YYYY-MM-DD').raw().max('now').allow('').allow(null),
  documentIssuingAuthority: Joi.string().required().empty(''),
}))

const addressSchema = () => Joi.object({
  addressLine1: Joi.string().required().empty('').max(40).messages({
    'string.max': 'The input value must not exceed 40 characters',
  }),
  addressLine2: Joi.string().empty('').max(40).messages({
    'string.max': 'The input value must not exceed 40 characters',
  }),
  city: Joi.string().required().empty('').max(20).messages({
    'string.max': 'The input value must not exceed 20 characters',
  }),
  country: Joi.string().required().empty(''),
  postcode: Joi.string().required().empty('')
    .min(3).max(10)
    .regex(/^[A-Za-z0-9 ]*$/).messages({
      'string.max': 'Postcode must consist of 3 to 10 characters.',
      'string.pattern.base': 'This input field allows alphanumeric characters and spaces only',
    }),
  state: Joi.string().required().empty('').max(30).messages({
    'string.max': 'The input value must not exceed 30 characters',
  }),
})

const contactSchema = () => Joi.object({
  contactNo: Joi.string().regex(/^\d*$/).required().empty('')
    .max(20)
    .messages({
      'string.max': 'The input value must not exceed 20 digits',
      'string.pattern.base': 'This input field allows digits only',
    }),
  email: Joi.string().email({ tlds: { allow: false } }).required().empty('')
    .max(40)
    .messages({
      'string.max': 'The input value must not exceed 40 characters',
      'string.email': 'The input value is not a valid email address',
    }),
  countryCode: Joi.string().required().empty(''),
})

const professionalDetailsSchema = (isApplicant = false) => Joi.array().items(Joi.object({
  position: Joi.string().required().valid(...Object.keys(position)).empty(''),
  positionStartDate: isApplicant ? 
    Joi.date().format('YYYY-MM-DD').raw().max('now').required() : 
    Joi.date().when('position', {
      is: 'UBO',
      then: Joi.date().format('YYYY-MM-DD').raw().max('now').required(),
      otherwise: Joi.date().format('YYYY-MM-DD').raw().max('now'),
    }),
  sharePercentage: Joi.number().precision(2).when('position', {
    is: 'UBO',
    then: Joi.number().precision(2).max(100).min(0).required().empty(''),
    otherwise: Joi.number().precision(2).max(100).min(0).optional().empty(''),
  }),
}))

const taxDetailsSchema = (required = true) => Joi.array().items(Joi.object({
  country: required ? Joi.string().required().empty('') : Joi.string().empty(''),
  taxNumber: (required ? Joi.string().required().empty('') : Joi.string().empty(''))
    .max(64)
    .messages({
      'string.max': 'The input value must not exceed 64 characters',
    }),
}))

export const validationSchema = Joi.object({
  region: Joi.string().required().empty(''),
  businessDetails: Joi.object({
    additionalInfo: Joi.any(),
    searchReferenceId: Joi.any(),
    businessName: Joi.string().required().empty(''),
    businessRegistrationNumber: Joi.string().regex(/((N|n)\/(A|a)|(N|n).(A|a).|(N|n).(A|a)|(N|n)(A|a))/, { invert: true }).required().empty(''),
    tradeName: Joi.string().required().empty(''),
    website: Joi.string().empty(''),
    businessType: Joi.string().required().valid(...Object.keys(businessTypes)),
    documentDetails: corporateDocumentSchema(),
    legalDetails: Joi.object({
      registeredDate: Joi.date().format('YYYY-MM-DD').raw().max('now').required(),
      registeredCountry: Joi.string().required().empty(''),
    }),
    addresses: Joi.object({
      registeredAddress: addressSchema(),
      isSameAddress: Joi.string().required().valid('YES', 'NO').empty(''),
      businessAddress: addressSchema(),
    }),
    taxDetails: taxDetailsSchema(),
    // moved - keep in the schema to avoid errors on previously saved data
    stakeholders: Joi.any().optional().strip()
  }),
  stakeholders: Joi.array().items(Joi.object({
    stakeholderType: Joi.string().required().valid(...Object.values(NIUM_STAKEHOLDER_TYPE)).empty(''),
    businessPartner: Joi.object().when('stakeholderType', {
      is: NIUM_STAKEHOLDER_TYPE.INDIVIDUAL,
      then: Joi.optional().allow(),
      otherwise: Joi.object({
        businessName: Joi.string().required().empty(''),
        businessRegistrationNumber: Joi.string().required().empty(''),
        businessEntityType: Joi.string().required().valid(...Object.keys(position)).empty(''),
        sharePercentage: Joi.number().precision(2).when('businessEntityType', {
          is: 'UBO',
          then: Joi.number().precision(2).max(100).min(0).required().empty(''),
          otherwise: Joi.number().precision(2).max(100).min(0).optional().empty(''),
        }),
        legalDetails: Joi.object({
          registeredCountry: Joi.string().required().empty(''),
        }),
        documentDetails: corporateDocumentSchema(true),
        // unused data that can come from the exhaustive corporate details API
        addresses: Joi.any().optional().strip()
      }).required()
    }),
    stakeholderDetails: Joi.object().when('stakeholderType', {
      is: NIUM_STAKEHOLDER_TYPE.CORPORATE,
      then: Joi.optional().allow(null, {}),
      otherwise: Joi.object({
        firstName: Joi.string().required().empty('').max(40).messages({
          'string.max': 'The input value must not exceed 40 characters',
        }),
        middleName: Joi.string().empty('').max(40).messages({
          'string.max': 'The input value must not exceed 40 characters',
        }),
        lastName: Joi.string().required().empty('').max(40).messages({
          'string.max': 'The input value must not exceed 40 characters',
        }),
        nationality: Joi.string().required().empty(''),
        dateOfBirth: Joi.date().format('YYYY-MM-DD').raw().max('now').required(),
        birthCountry: Joi.string().required().empty(''),
        isPep: Joi.string().required().valid('Yes', 'No').empty(''),
        address: addressSchema(),
        contactDetails: contactSchema(),
        professionalDetails: professionalDetailsSchema(),
        taxDetails: Joi.any().when('professionalDetails.0.position', {
          is: 'UBO',
          then: taxDetailsSchema(),
          otherwise: taxDetailsSchema(false)
        }),
        documentDetails: personDocumentSchema(true)
      }).required()
    }),
  })),
  applicantDetails: Joi.object({
    firstName: Joi.string().required().empty('').max(40).messages({
      'string.max': 'The input value must not exceed 40 characters',
    }),
    middleName: Joi.string().empty('').max(40).messages({
      'string.max': 'The input value must not exceed 40 characters',
    }),
    lastName: Joi.string().required().empty('').max(40).messages({
      'string.max': 'The input value must not exceed 40 characters',
    }),
    nationality: Joi.string().required().empty(''),
    dateOfBirth: Joi.date().format('YYYY-MM-DD').raw().max('now').required(),
    birthCountry: Joi.string().required().empty(''),
    isPep: Joi.string().required().valid('Yes', 'No').empty(''),
    contactDetails: contactSchema(),
    address: addressSchema(),
    professionalDetails: professionalDetailsSchema(true),
    documentDetails: personDocumentSchema(false)
  }),
  riskAssessmentInfo: Joi.object({
    totalEmployees: Joi.string().required().valid(...Object.keys(totalEmployees)).empty(''),
    annualTurnover: Joi.string().required().valid(...Object.keys(annualTurnover)).empty(''),
    industrySector: Joi.string().required().valid(...Object.keys(industrySector)).empty(''),
    transactionCountries: Joi.array().items(Joi.string()).min(1).required(),
    countryOfOperation: Joi.array().items(Joi.string()).min(1).required(),
    intendedUseOfAccount: Joi.string().required().valid(...Object.keys(intendedUseOfAccount)).empty('')
  }),
  // from previous steps
  tos: Joi.object({
    providerAccept: Joi.boolean().required().valid(true),
    tlpayAccept: Joi.boolean().required().valid(true),
  }),
  brnNumber: Joi.any(),
  brnOther: Joi.any(),
  countryCode: Joi.any(),
  searchReferenceId: Joi.any(),
})