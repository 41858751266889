import Joi from 'joi'

export const formSchema = (entityType = 'USER') => {
  let fields = {
    password: Joi.string().required().messages({ 'any.required': 'Password is required' }),
    confirmPassword: Joi.any().valid(Joi.ref('password')).required().messages({
      'any.only': 'Password doesn`t match',
      'any.required': 'Repeated password is required',
    }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({ 'any.required': 'Email is required', 'string.empty': 'Email is required' }),
    name: Joi.string().required().messages({ 'any.required': 'Name is required', 'string.empty': 'Name is required' }),
    role: Joi.string(),
  }
  if (entityType === 'COMPANY') {
    fields = {
      address: Joi.string().max(255).required().messages({
        'any.required': 'Company address is required',
        'string.required': 'Company address is required',
        'string.empty': 'Company address is required',
      }),
      city: Joi.string().max(100).required().messages({
        'any.required': 'City is required',
        'string.required': 'City is required',
        'string.empty': 'City is required',
      }),
      telephone: Joi.string().max(50).allow('', null),
      website: Joi.string().uri().allow('', null),
      timezone: Joi.string().max(40).allow('', null),
      countryId: Joi.string().required().messages({
        'any.required': 'Country is required',
        'string.required': 'Country is required',
        'string.empty': 'Country is required',
      }),
      acceptTos: Joi.boolean().required().invalid(false),
      companyName: Joi.string().required().messages({
        'any.required': 'Company name is required',
        'string.required': 'Company name is required',
        'string.empty': 'Company name is required',
      }),
      // company ids
      emailID: Joi.string().email({ tlds: { allow: false } }).required().max(30).messages({
        'any.required': 'Email ID is required',
        'string.required': 'Email ID is required',
        'string.email': 'Email ID must be a valid email address',
        'string.empty': 'Email ID is required',
        'string.max': 'Email ID cannot be longer than 12 characters'
      }),
      abtaID: Joi.string().empty('').$.min(5).max(5).rule({
        message: 'ABTA code must contain 5 characters'
      }),
      iataID: Joi.string().empty('').$.min(8).max(12).rule({
        message: 'IATA code must contain between 8 and 12 characters'
      }),
      tID: Joi.string().empty('').$.min(10).max(10).rule({
        message: 'TIDS code must contain 10 characters'
      }),
      ...fields,
    }
  }
  return Joi.object(fields)
}
